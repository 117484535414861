@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "font";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-4Regular.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: font;
}

.pagination-item {
  cursor: pointer;
}

.pagination-active {
  background-color: #ff4400;
  color: white;
  border: none;
}
